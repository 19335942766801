/* eslint-disable @typescript-eslint/naming-convention */
/*
 *
 * Colors
 *
 * Use this file for customizing the theme's color values.
 *
 * You can see the default values here:
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/colors.ts
 */

const magenta = {
  dark: "#A40182",
  veryDark: "#50005F",
};
const lightPink = "#FF41BD";
const softBlue = "#BAC2FD";
const lightSoftBlue = "#F4BCFF";
const lightCyan = "#55FFE0";
const yellowGreen = "#D7FF6E";
const lightBlueGrey = "#F0F0F7";
const grayMagenta = "#C0ACC4";

const colors = {
  brand: {
    50: magenta.veryDark,
    100: magenta.veryDark,
    200: magenta.veryDark,
    300: magenta.veryDark,
    400: magenta.veryDark,
    500: magenta.veryDark,
    600: magenta.veryDark,
    700: magenta.veryDark,
    800: magenta.veryDark,
    900: magenta.veryDark,
  },
  /** This color scheme is a little wacky to enable a _brighter_ hover color for
   * buttons than the base. The hue numbers are set specifically to work with
   * the default button theme `colorScheme` treatment. */
  pinkButton: {
    200: magenta.dark,
    300: magenta.dark,
    400: magenta.dark,
    500: magenta.dark,
    600: lightPink,
    700: lightPink,
  },
  lightPinkButton: {
    50: "#ffdef4",
    100: lightPink,
    200: lightPink,
    300: lightPink,
    400: lightPink,
    500: lightPink,
    600: magenta.dark,
    700: magenta.dark,
  },
  darkMagentaButton: {
    200: magenta.dark,
    300: magenta.dark,
    400: magenta.dark,
    500: magenta.dark,
    600: magenta.veryDark,
    700: magenta.veryDark,
  },
  softBlueButton: {
    50: "#eceefe",
    100: "#eceefe",
    200: "#eceefe",
    300: "#eceefe",
    400: "#eceefe",
    500: "#d3d8fe",
    600: softBlue,
    700: "#8896fc",
  },
  grayMagentaButton: {
    200: grayMagenta,
    300: grayMagenta,
    400: grayMagenta,
    500: grayMagenta,
    600: magenta.dark,
    700: magenta.dark,
    checked: magenta.veryDark,
  },
  yellowGreen,
  pinkSlider: {
    200: lightPink,
    500: lightPink,
    700: magenta.veryDark,
  },
  magenta,
  grayMagenta,
  lightPink,
  softBlue,
  lightSoftBlue,
  lightCyan,
  lightBlueGrey,
  cardBackground: lightBlueGrey,
};
export default colors;
