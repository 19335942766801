import { useEffect, useRef } from "react";

export default function usePrevious<V>(value: V) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
