import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    inputMd: {
      textAlign: "left",
      fontSize: "sm",
      mt: 5,
      ml: 7,
    },
    inputSm: {
      textAlign: "left",
      fontSize: "sm",
      mt: 5,
      ml: 2,
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
});
