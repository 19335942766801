import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { alertAnatomy as parts } from "@chakra-ui/anatomy";

const msch = createMultiStyleConfigHelpers(parts.keys);

export default msch.defineMultiStyleConfig({
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    authInput: {
      container: {
        mt: 2,
        w: "90%",
        mx: "auto",
        bg: "red.200",
        fontSize: 14,
        py: 2,
      },
      icon: {
        color: "red.500",
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
});
