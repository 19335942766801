import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    "&:not(.chakra-button)": {
      color: "magenta.veryDark",
      _hover: {
        color: "magenta.dark",
      },
    },
    _hover: {
      textDecoration: "none",
    },
    _disabled: {
      cursor: "default",
    },
    "tr[aria-selected=true] &": {
      color: "gray.300",
      _hover: {
        color: "gray.100",
      },
    },
  },
});
