import {
  ApolloQueryResult,
  DocumentNode,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  OperationVariables,
  QueryHookOptions,
  QueryResult as BaseQueryResult,
  TypedDocumentNode,
  // eslint-disable-next-line no-restricted-imports
  useQuery as baseUseQuery,
} from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { useAuth } from "utils/auth";
import usePrevious from "utils/usePrevious";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type DocumentVariablesType<
  TDocumentNode extends TypedDocumentNode<any, any>,
> = TDocumentNode extends TypedDocumentNode<any, infer TType> ? TType : never;
/* eslint-enable @typescript-eslint/no-explicit-any */
export interface QueryResult<
  TData = unknown,
  TVariables extends OperationVariables = never,
> extends BaseQueryResult<TData, TVariables> {
  /** We override the default `loading` variable so that it's also true before
   * the user is authenticated. Use `queryLoading` for the default Apollo
   * behavior. */
  loading: boolean;
  /** We override the default `loading` variable so that it's also true before
   * the user is authenticated, and store the original `loading` variable
   * returned from `useQuery` here. */
  queryLoading: boolean;
}
/**
 * An overridden version of `useQuery` that lets us set better default settings.
 * The changes are:
 * - Use `unknown` data and `never` variables default types instead of `any` to
 *   coerce devs into including the generated typings
 * - Disable queries in SSR by default (the environment doesn't currently
 *   support it)
 */
export function useIgnoreAuthQuery<
  TData = unknown,
  TVariables extends OperationVariables = never,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const result = baseUseQuery<TData, TVariables>(query, {
    ssr: false,
    ...options,
    skip: typeof window === "undefined" || options?.skip,
  });

  return {
    ...result,
    queryLoading: result.loading,
  };
}

/**
 * An overridden version of `useQuery` that lets us set better default settings.
 * The changes are:
 * - Use `unknown` data and `never` variables default types instead of `any` to
 *   coerce devs into including the generated typings
 * - Disable queries in SSR by default (the environment doesn't currently
 *   support it)
 * - Skip queries when the user is not authenticated (all queries require
 *   authentication)
 * - Set `loading` to `true` when the user is not authenticated (if the user is
 *   not authenticated, they should be redirected to the login page, and
 *   generally showing a loading state during the brief interval in between is
 *   usually better than not)
 */
export function useQuery<
  TData = unknown,
  TVariables extends OperationVariables = never,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const { isAuthenticated } = useAuth();

  const { loading, ...result } = useIgnoreAuthQuery<TData, TVariables>(query, {
    ...options,
    skip: !isAuthenticated || options?.skip,
  });

  return {
    ...result,
    loading: loading || (typeof window !== "undefined" && !isAuthenticated),
  };
}

export interface LoadMoreQueryHookOptions<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
> extends QueryHookOptions<TData, TVariables> {
  pageSize?: number;
  getListData: (
    data: TData,
  ) => unknown[] | Readonly<unknown[]> | undefined | null;
}

export type FetchNextPage<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
> = (
  fetchMoreOptions?: FetchMoreQueryOptions<TVariables, TData> &
    FetchMoreOptions<TData, TVariables>,
) => Promise<ApolloQueryResult<TData>>;

export interface LoadMoreQueryResult<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
> extends QueryResult<TData, TVariables> {
  fetchNextPage: FetchNextPage<TData, TVariables>;
}

export function useLoadMoreQuery<
  TData = unknown,
  TVariables extends { limit?: number | null; offset?: number | null } = {
    limit?: number;
    offset?: number;
  },
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: LoadMoreQueryHookOptions<TData, TVariables>,
): LoadMoreQueryResult<TData, TVariables> {
  const { pageSize = 5, getListData, variables, ...apolloOptions } = options;

  const [limit, setLimit] = useState(pageSize);

  const [deferredVariables, setDeferredVariables] = useState<
    TVariables | undefined
  >(variables);
  const prevVariables = usePrevious(variables);

  const isLimitResetRequired = limit !== pageSize;

  useEffect(() => {
    if (!isEqual(variables, prevVariables)) {
      if (isLimitResetRequired) {
        setLimit(pageSize);
      }
      setDeferredVariables(variables);
    }
  }, [variables, prevVariables, pageSize, isLimitResetRequired]);

  const { refetch: standardRefetch, ...result } = useQuery(query, {
    ...apolloOptions,
    skip:
      apolloOptions.skip ||
      (isLimitResetRequired && !isEqual(variables, deferredVariables)),
    variables: { limit, ...variables } as TVariables,
  });

  const refetch = useCallback(
    (refetchVariables?: Partial<TVariables>) =>
      standardRefetch({
        limit: pageSize,
        offset: 0,
        ...refetchVariables,
      } as Partial<TVariables>),
    [standardRefetch, pageSize],
  );

  return useMemo(
    () => ({
      ...result,
      refetch,
      fetchNextPage: (fetchMoreOptions) => {
        const { variables: fetchMoreVariables, ...restFetchMoreOptions } =
          fetchMoreOptions || {};
        const currentLength =
          (result.data && getListData(result.data))?.length || 0;
        return result
          .fetchMore({
            ...restFetchMoreOptions,
            variables: {
              limit: pageSize,
              offset: currentLength,
              ...fetchMoreVariables,
            } as Partial<TVariables>,
          })
          .then((fetchMoreResult) => {
            const newLength =
              (fetchMoreResult.data &&
                getListData(fetchMoreResult.data)?.length) ||
              0;
            setLimit(currentLength + newLength);
            return fetchMoreResult;
          });
      },
    }),
    [result, refetch, getListData, pageSize],
  );
}
