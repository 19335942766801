import { useTheme } from "@chakra-ui/react";
import {
  whiten,
  blacken,
  darken,
  lighten,
  transparentize,
} from "@chakra-ui/theme-tools";

export function makeGetWhiten(theme: ReturnType<typeof useTheme>) {
  return function getWhiten(color: string, amount: number) {
    // NOTE: Chakra missed some: https://github.com/chakra-ui/chakra-ui/pull/7158
    return whiten(color, amount / 100)(theme);
  };
}

export function useGetWhiten() {
  const theme = useTheme();
  return makeGetWhiten(theme);
}

export function makeGetBlacken(theme: ReturnType<typeof useTheme>) {
  return function getBlacken(color: string, amount: number) {
    return blacken(color, amount)(theme);
  };
}

export function useGetBlacken() {
  const theme = useTheme();
  return makeGetBlacken(theme);
}

export function makeGetLighten(theme: ReturnType<typeof useTheme>) {
  return function getLighten(color: string, amount: number) {
    return lighten(color, amount)(theme);
  };
}

export function useGetLighten() {
  const theme = useTheme();
  return makeGetLighten(theme);
}

export function makeGetDarken(theme: ReturnType<typeof useTheme>) {
  return function getDarken(color: string, amount: number) {
    return darken(color, amount)(theme);
  };
}

export function useGetDarken() {
  const theme = useTheme();
  return makeGetDarken(theme);
}

export function makeGetTransparency(theme: ReturnType<typeof useTheme>) {
  return function getTransparency(color: string, opacity: number) {
    return transparentize(color, opacity)(theme);
  };
}

export function useGetTransparency() {
  const theme = useTheme();
  return makeGetTransparency(theme);
}
