import * as Sentry from "@sentry/nextjs";
import type { CurrentUserFieldsFragment } from "__generated__/gql/graphql";

export function setCurrentUser(
  userData:
    | Partial<Pick<CurrentUserFieldsFragment, "id" | "email">>
    | null
    | undefined,
): void {
  if (!userData) return;
  const { id, email } = userData;
  if (!id) return;
  Sentry.setUser({ id, username: email, email });
}

export function clearCurrentUser(): void {
  Sentry.configureScope((scope) => scope.setUser(null));
}
