import { ReactNode, useCallback } from "react";
import { ApolloError } from "@apollo/client";
import { useToast, UseToastOptions } from "@chakra-ui/react";
import ToastError from "utils/ToastError";
import UserError from "utils/UserError";
import getServerErrorMessage from "utils/apollo/getServerErrorMessage";
import useLatestRef from "utils/useLatestRef";

const DEFAULT_OPTIONS: UseToastOptions = { isClosable: true, duration: 4000 };

type Options = {
  networkStatusMessages?: { [status: string]: string };
};

export default function useAsyncCompleteToast({
  networkStatusMessages,
}: Options = {}) {
  const latestNetworkStatusMessagesRef = useLatestRef(networkStatusMessages);

  const showToast = useToast(DEFAULT_OPTIONS);

  const showSuccessToast = useCallback(
    (successMessage: ReactNode = "Success!", options?: UseToastOptions) =>
      showToast({
        status: "success",
        duration: 2500,
        title: successMessage,
        ...options,
      }),
    [showToast],
  );

  const showErrorToast = useCallback(
    (error?: unknown, options?: UseToastOptions) => {
      const defaultErrorToastOptions: UseToastOptions = { status: "error" };

      if (error && error instanceof ToastError) {
        return showToast({
          ...defaultErrorToastOptions,
          ...error.toastOptions,
          ...options,
        });
      }

      if (error && error instanceof UserError) {
        return showToast({
          ...defaultErrorToastOptions,
          status: error.status,
          title: error.title,
          description: error.description,
          ...options,
        });
      }

      if (error && error instanceof ApolloError) {
        const serverErrorMessage = getServerErrorMessage(
          error,
          latestNetworkStatusMessagesRef.current,
        );
        return showToast({
          ...defaultErrorToastOptions,
          title: "An error occurred making the request",
          description: serverErrorMessage || error.message,
          ...options,
        });
      }

      return showToast({
        ...defaultErrorToastOptions,
        title: "An unexpected error occurred",
        ...options,
      });
    },
    [showToast, latestNetworkStatusMessagesRef],
  );

  return { showToast, showSuccessToast, showErrorToast };
}
