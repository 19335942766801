import { useEffect, useState } from "react";
import tokenService, {
  AUTH_TOKEN_CHANGE_EVENT,
  Token,
} from "utils/TokenService";

export default function useAuthToken() {
  const [token, setToken] = useState<Token | undefined>(tokenService.tokenObj);

  useEffect(() => {
    const listener = () => {
      setToken(tokenService.tokenObj);
    };
    tokenService.addEventListener(AUTH_TOKEN_CHANGE_EVENT, listener);
    return () => {
      tokenService.removeEventListener(AUTH_TOKEN_CHANGE_EVENT, listener);
    };
  }, []);

  return token;
}
