import { ReactElement, useCallback, useEffect } from "react";
import { useToast, UseToastOptions } from "@chakra-ui/react";
import ToastError from "utils/ToastError";
import UserError from "utils/UserError";
import {
  ASYNC_VOID_HANDLED_EVENT,
  isAsyncVoidHandledEvent,
} from "utils/asyncVoidHandler";
import { getIsNextCancelledError } from "utils/metabase";

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  status: "error",
  duration: 5e3,
  isClosable: true,
  title: "An unexpected error occurred",
};

function isErrorEvent(event: Event): event is ErrorEvent {
  return event.type === "error";
}

function isUnhandledRejection(event: Event): event is PromiseRejectionEvent {
  return event.type === "unhandledrejection";
}

export function useToastUnhandledError() {
  const showToast = useToast(DEFAULT_TOAST_OPTIONS);

  const handleErrorEvent = useCallback(
    (event: Event) => {
      const error = ((isUnhandledRejection(event) && event.reason) ||
        (isAsyncVoidHandledEvent(event) &&
          !event.detail.isIgnored &&
          event.detail.reason) ||
        (isErrorEvent(event) && event.error)) as unknown;

      if (!error) return;

      if (error instanceof ToastError) {
        return showToast(error.toastOptions);
      }

      if (error instanceof UserError) {
        return showToast({ description: error.message });
      }

      if (getIsNextCancelledError(error)) return;

      showToast();
    },
    [showToast],
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("error", handleErrorEvent);
    window.addEventListener("unhandledrejection", handleErrorEvent);
    window.addEventListener(ASYNC_VOID_HANDLED_EVENT, handleErrorEvent);

    return () => {
      window.removeEventListener("error", handleErrorEvent);
      window.removeEventListener("unhandledrejection", handleErrorEvent);
      window.removeEventListener(ASYNC_VOID_HANDLED_EVENT, handleErrorEvent);
    };
  }, [handleErrorEvent]);
}

type Props = { children: ReactElement };

export default function ToastUnhandledErrorWrapper({ children }: Props) {
  useToastUnhandledError();
  return children;
}
