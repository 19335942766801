import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
// eslint-disable-next-line import/no-extraneous-dependencies
import { sliderAnatomy as parts } from "@chakra-ui/anatomy";

const msch = createMultiStyleConfigHelpers(parts.keys);

export default msch.defineMultiStyleConfig({
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {
    md: {
      thumb: {
        boxSize: "23px",
      },
    },
  },

  variants: {
    flat: (props) => ({
      track: {
        borderRadius: "full",
        bg:
          props.colorScheme &&
          mode(`${props.colorScheme}.700`, "whiteAlpha.200")(props),
        overflow: "visible",
      },
      filledTrack: {
        height: "200%",
        borderRadius: "full",
      },
      thumb: {
        bg:
          props.colorScheme &&
          mode(`${props.colorScheme}.500`, `${props.colorScheme}.200`)(props),
        boxShadow: "none",
      },
    }),
  },

  defaultProps: {
    variant: "flat",
    colorScheme: "pinkSlider",
  },
});
