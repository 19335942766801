import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import components from "./components";
import foundations from "./foundations";
import styles from "./styles";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const breakpoints = {
  sm: "30em",
  md: "52em",
  lg: "66em",
  xl: "80em",
  "2xl": "96em",
};

const theme = extendTheme({
  styles,
  ...foundations,
  components,
  config,
  breakpoints,
});

export default theme;
