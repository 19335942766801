import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Text,
  Spinner,
} from "@chakra-ui/react";
import ModalBlurOverlay from "components/Shared/ModalBlurOverlay";

type Props = {
  children?: React.ReactNode;
  header?: React.ReactNode;
  isOpen?: boolean;
};

export default function RedirectingOverlay({
  children,
  header = "Redirecting",
  isOpen = false,
}: Props) {
  return (
    <Modal isOpen={isOpen} isCentered onClose={() => {}} size="xs">
      <ModalBlurOverlay />
      <ModalContent textAlign="center" userSelect="none" pointerEvents="none">
        {header && <ModalHeader color="magenta.dark">{header}</ModalHeader>}
        <ModalBody>
          {children && (
            <Text mb={8} mx={4}>
              {children}
            </Text>
          )}
          <Spinner size="xl" color="lightPink" thickness="4px" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
