import { gql } from "__generated__/gql";
import { serverGql } from "__generated__/serverGql";

export const CURRENT_BRAND_FIELDS = gql(/* GraphQL */ `
  fragment CurrentBrandFields on Brand {
    id
    name
    isSubscriptionValid
    layout
    pitchableAccount {
      brandId
    }
    brandModulesList {
      id
      module
    }
  }
`);

export const CURRENT_BRAND = gql(/* GraphQL */ `
  query CurrentBrand($brandId: UUID!) {
    brand(id: $brandId) {
      ...CurrentBrandFields
    }
  }
`);

export const CURRENT_USER_FIELDS = gql(/* GraphQL */ `
  fragment CurrentUserFields on User {
    id
    firstName
    lastName
    email
    isActive
    isStaff
    isSuperuser
    canAccessAllBrands
    lastLogin
    createdAt
    image(size: 60)
    featureUsersList {
      id
      feature
    }
  }
`);

export const GET_TOKEN = serverGql(/* ServerGraphQL */ `
  mutation GetToken($email: String!, $password: String!) {
    auth: tokenAuth(email: $email, password: $password) {
      token
      refreshToken
      refreshExpiresIn
    }
  }
`);

export const REFRESH_TOKEN = serverGql(/* ServerGraphQL */ `
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`);

export const REVOKE_TOKEN = serverGql(/* ServerGraphQL */ `
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`);

export const FETCH_CURRENT_USER = gql(/* GraphQL */ `
  query FetchCurrentUser($brandId: UUID!, $hasBrandId: Boolean = false) {
    me {
      ...CurrentUserFields
      brands(first: 1, orderBy: CREATED_AT_ASC) {
        totalCount
        nodes {
          id
          ...CurrentBrandFields
        }
      }
    }
    brands {
      totalCount
    }
    brand(id: $brandId) @include(if: $hasBrandId) {
      ...CurrentBrandFields
    }
    activeAppAlertsList {
      id
      heading
      body
      status
    }
  }
`);
