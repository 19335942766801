import Input from "./Input";

// eslint-disable-next-line @typescript-eslint/naming-convention
const NumberInput =
  // `Input` and `NumberInput` are not fully compatible (they have different
  // `parts`), but the custom styles currently are identical so we can just have
  // `NumberInput` inherit all the customizations from `Input` for now. This may
  // not always be the case...
  Input;

export default NumberInput;
