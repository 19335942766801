import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  defaultProps: {
    colorScheme: "darkMagentaButton",
  },
  baseStyle: {
    control: {
      "&:empty::after": {
        content: '" "',
        visibility: "hidden",
      },
    },
  },
});
