/*
 * Foundations
 *
 * Use this file to modify the design tokens in the base Chakra theme.
 *
 * You can see how this is setup in the base theme here:
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/index.ts
 */

import colors from "./colors";
import shadows from "./shadows";
import typography from "./typography";

const foundations = {
  colors,
  shadows,
  ...typography,
};

export default foundations;
