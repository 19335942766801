/* eslint-disable import/prefer-default-export */
import { useDebounceCallback } from "@react-hook/debounce";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import type { CurrentUserFieldsFragment } from "__generated__/gql/graphql";

export function getIsSegmentEnabled() {
  return process.env.NODE_ENV === "production";
}

export function useSegmentTrackPage() {
  const router = useRouter();
  const { isReady, asPath } = router;

  const previousAsPath = useRef<string>();

  const trackPage = useDebounceCallback(
    (properties: { [property: string]: unknown }) => {
      if (typeof window.analytics !== "undefined" && getIsSegmentEnabled()) {
        window.analytics.page(properties);
      }
    },
    250,
  );

  useEffect(() => {
    if (isReady) {
      if (previousAsPath.current) {
        trackPage({
          referrer: `${window.location.origin || ""}${previousAsPath.current}`,
        });
      }
      previousAsPath.current = asPath;
    }
  }, [trackPage, isReady, asPath]);
}

let lastSegmentUserId: string | undefined;

export function identifySegmentUser(
  userData:
    | Partial<
        Pick<
          CurrentUserFieldsFragment,
          "id" | "email" | "firstName" | "lastName" | "createdAt" | "isStaff"
        >
      >
    | null
    | undefined,
): void {
  const { id, email, firstName, lastName, createdAt, isStaff } = userData || {};

  if (
    !getIsSegmentEnabled() ||
    typeof window === "undefined" ||
    typeof window.analytics === "undefined" ||
    !id ||
    // Avoid sending duplicative `identify` events
    lastSegmentUserId === id
  ) {
    return;
  }

  window.analytics.identify(id, {
    createdAt,
    email,
    firstName,
    lastName,
    isStaff,
  });

  lastSegmentUserId = id;
}

export const trackEvent: typeof window.analytics.track = (
  event: string,
  ...args
) => {
  if (!getIsSegmentEnabled()) return;

  if (typeof window === "undefined" || !window.analytics) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to track event: ${event}`);
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.analytics.track(event, ...args);
};
