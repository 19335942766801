import { ApolloError, ServerError, ServerParseError } from "@apollo/client";
import { captureException } from "@sentry/nextjs";
import { AssertionError } from "utils/assertions";

export function isServerError(
  error: Error | ServerError | ServerParseError,
): error is ServerError {
  return error.name === "ServerError" && "statusCode" in error;
}

export type MessageByStatus = { [status: number]: string };

const MESSAGE_BY_STATUS: MessageByStatus = {
  413: "Request was too large, please try making a smaller request.",
};

export default function getServerErrorMessage(
  error: Error,
  messagesByStatus?: MessageByStatus,
) {
  if (!(error instanceof ApolloError)) return;

  const { networkError } = error;

  if (!networkError) return;

  if (!isServerError(networkError)) {
    console.error(networkError);
    captureException(networkError);
    if (networkError instanceof AssertionError) {
      return "Received an unexpected value";
    }
    return;
  }

  const allMessages: MessageByStatus = {
    ...MESSAGE_BY_STATUS,
    ...messagesByStatus,
  };

  const customMsg = allMessages[networkError.statusCode];

  if (customMsg) return customMsg;

  return error.message;
}
