import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    overflowWrap: "normal",
  },
  // Styles for the size variations
  sizes: {
    xs: {
      fontSize: ["md", null, "lg"],
      lineHeight: 1.2,
    },
    sm: {
      fontSize: ["lg", null, "xl"],
      lineHeight: 1.2,
    },
    md: {
      fontSize: ["xl", null, "2xl"],
      lineHeight: 1.2,
    },
    lg: {
      lineHeight: 1,
    },
    xl: {
      lineHeight: 1,
    },
  },
  // Styles for the visual style variations
  variants: {
    "headline-heavy": {
      fontWeight: "bold",
      fontSize: "3xl",
    },
    "section-header": {
      fontSize: ["2xl", null, "3xl"],
      color: "magenta.veryDark",
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
});
