import { useMemo } from "react";
import { QueryHookOptions, useApolloClient } from "@apollo/client";
import { getFragmentData } from "__generated__/gql";
import {
  Feature,
  FetchCurrentUserQuery,
  FetchCurrentUserQueryVariables,
  Module,
} from "__generated__/gql/graphql";
import { asyncVoid } from "utils/asyncVoidHandler";
import { INVALID_UUID } from "utils/misc";
import { useQuery } from "utils/apollo/hooks";
import { resetEphemeralBrandStorage } from "utils/EphemeralBrandStorage";
import {
  CURRENT_BRAND,
  CURRENT_BRAND_FIELDS,
  CURRENT_USER_FIELDS,
  FETCH_CURRENT_USER,
} from "./authQueries";
import { brandIdLocalStorage, useAuth } from "./auth";
import { setCurrentUser } from "./sentryContext";
import { identifySegmentUser } from "./segment";

export function useSetCurrentBrandId() {
  const { currentBrandId, setCurrentBrandId: setContext } = useAuth();
  const client = useApolloClient();

  return async (brandId: string | null) => {
    if (currentBrandId !== brandId) await client.clearStore();

    resetEphemeralBrandStorage();

    setContext(brandId);

    if (brandId) {
      brandIdLocalStorage.set(brandId);
    } else {
      brandIdLocalStorage.remove();
    }
  };
}

/** A React hook to fetch the current user's data. */
export function useFetchCurrentUser(
  options?: QueryHookOptions<
    FetchCurrentUserQuery,
    FetchCurrentUserQueryVariables
  >,
) {
  const { isAuthenticated, currentBrandId } = useAuth();
  const setCurrentBrandId = useSetCurrentBrandId();
  return useQuery(FETCH_CURRENT_USER, {
    ...options,
    variables: {
      // Give it a fake `brandId` if we don't have one – it won't be called because of `hasBrandId`
      brandId: currentBrandId || INVALID_UUID,
      hasBrandId: !!currentBrandId,
    },
    skip: options?.skip || !isAuthenticated,
    onCompleted: (data) => {
      if (
        (!currentBrandId || !data?.brand) &&
        data?.me?.brands?.totalCount === 1
      ) {
        asyncVoid(setCurrentBrandId(data.me.brands?.nodes[0].id));
      }
      const currentUserData = getFragmentData(CURRENT_USER_FIELDS, data.me);
      identifySegmentUser(currentUserData);
      setCurrentUser(currentUserData);
      options?.onCompleted?.(data);
    },
  });
}

export function useCurrentUserData() {
  const { data } = useFetchCurrentUser();
  return getFragmentData(CURRENT_USER_FIELDS, data?.me);
}

export function useCurrentUserId() {
  const userData = useCurrentUserData();
  return userData?.id;
}

/** A React hook to return the ID of the brand currently active for the user. */
export function useCurrentBrandId() {
  const { currentBrandId } = useAuth();
  return currentBrandId;
}

export function useFetchCurrentBrand() {
  const brandId = useCurrentBrandId();
  const userId = useCurrentUserId();
  return useQuery(CURRENT_BRAND, {
    skip: !brandId || !userId,
    variables: brandId ? { brandId } : undefined,
  });
}

export function useCurrentBrandData() {
  const { data } = useFetchCurrentBrand();
  return getFragmentData(CURRENT_BRAND_FIELDS, data?.brand);
}

export function useActiveAppAlerts() {
  const { data } = useFetchCurrentUser();
  return data?.activeAppAlertsList;
}

export function useIsInvalidSubscription() {
  const brand = useCurrentBrandData();
  const { isSubscriptionValid } = brand || {};
  if (typeof isSubscriptionValid !== "boolean") return;
  return !isSubscriptionValid;
}

export function useUserHasFeature(feature: Feature): boolean | undefined {
  const userData = useCurrentUserData();
  return userData?.featureUsersList?.some((fu) => fu.feature === feature);
}

export function useBrandModules() {
  const brandData = useCurrentBrandData();
  return useMemo(
    () => brandData?.brandModulesList.map((bm) => bm.module),
    [brandData?.brandModulesList],
  );
}

export function useBrandHasModule<M extends Module>(...modules: M[]) {
  const brandModules = useBrandModules();
  return modules.map((module) => brandModules?.includes(module));
}
