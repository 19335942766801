import { captureException, captureMessage } from "@sentry/nextjs";

export const ASYNC_VOID_HANDLED_EVENT = "asyncVoidHandled";

export type AsyncVoidHandledEventDetails = {
  reason: unknown;
  isIgnored: boolean;
};

export type AsyncVoidHandledEvent = CustomEvent<AsyncVoidHandledEventDetails>;

export function isAsyncVoidHandledEvent(
  event: Event,
): event is AsyncVoidHandledEvent {
  return event.type === ASYNC_VOID_HANDLED_EVENT;
}

function sendEvent(reason: unknown, isIgnored = false) {
  if (typeof window === "undefined") return;

  const event = new CustomEvent<AsyncVoidHandledEventDetails>(
    ASYNC_VOID_HANDLED_EVENT,
    { detail: { reason, isIgnored } },
  );

  window.dispatchEvent(event);
}

export function logAsyncCatch(reason: unknown) {
  // eslint-disable-next-line no-console
  console.error(reason);

  if (reason instanceof Error) {
    captureException(reason);
  } else {
    captureMessage(String(reason), { extra: { reason } });
  }
}

/**
 * Takes a promise as an argument and returns void. Mostly useful for
 * `asyncVoidHandler`, but sometimes useful on its own to prevent 'floating' promises.
 */
export function asyncVoid(promise: Promise<unknown>, isIgnored = false): void {
  promise.catch((reason: unknown) => {
    if (!isIgnored) logAsyncCatch(reason);
    sendEvent(reason, isIgnored);
  });
}

/**
 * A handler that can be used to wrap promise-returning functions used in
 * event handlers where only void-returning functions are expected.
 */
function asyncVoidHandler<Args extends unknown[]>(
  handler: (...args: Args) => Promise<unknown>,
  isIgnored = false,
) {
  return function voidHandler(...args: Args): void {
    asyncVoid(handler(...args), isIgnored);
  };
}

export default asyncVoidHandler;
