/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMemo } from "react";
import {
  DropdownIndicatorProps,
  GroupBase,
  Props,
  StylesConfig,
  components as selectComponents,
  CSSObjectWithLabel,
} from "react-select";
import {
  Box,
  InputProps,
  Spinner,
  toCSSObject,
  useMultiStyleConfig,
  useTheme,
} from "@chakra-ui/react";
import { getColor } from "@chakra-ui/theme-tools";
import { CSSObject } from "@emotion/react";
import { pickBy, omitBy } from "lodash";
import { makeGetTransparency } from "utils/colorHooks";

type StyleGetter<P> = (
  base: CSSObjectWithLabel,
  props: P,
) => CSSObjectWithLabel;

function makeGetStylesWithOverride<P>(
  getStyles: StyleGetter<P>,
  getOverride?: StyleGetter<P>,
) {
  return function getStylesWithOverride(base: CSSObjectWithLabel, props: P) {
    const styles = getStyles(base, props);
    return { ...styles, ...getOverride?.(styles, props) };
  };
}

function DropdownIndicator<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
  const { selectProps } = props;
  return (
    <selectComponents.DropdownIndicator<Option, IsMulti, Group> {...props}>
      <Box
        as="span"
        transform={selectProps.menuIsOpen ? "rotate(180deg)" : undefined}
        transition="transform 200ms"
      >
        ▾
      </Box>
    </selectComponents.DropdownIndicator>
  );
}

function LoadingIndicator() {
  return <Spinner size="sm" color="gray.400" mr={3} />;
}

export const defaultComponents = {
  DropdownIndicator,
  IndicatorSeparator: null,
  LoadingIndicator,
};

const pseudoPropsByLookup = {
  disabled: "&[disabled]",
  focus: "&:focus",
  hover: "&:hover",
  invalid: "&[aria-invalid=true]",
} as const;

type PseudoProps = keyof typeof pseudoPropsByLookup;

const pseudoKeys = Object.keys(pseudoPropsByLookup) as unknown as PseudoProps[];

const isBorderProp = (v: unknown, k: unknown) =>
  typeof k === "string" && k.startsWith("border");

const isFontProp = (v: unknown, k: unknown) =>
  typeof k === "string" && k.startsWith("font");

export type SelectThemingProps = {
  size?: InputProps["size"];
};

/**
 * A hook that returns props that can be used to apply Chakra/theme styles to
 * `react-select` components. Styles are applied using the `styles` prop, and
 * certain components are replaced using the `components` prop.
 *
 * The hook approach is used to enable the application of these styles across
 * the various `react-select` components, without needing to create wrapped
 * versions of each. Additionally, since the `react-select` components use
 * TypeScript generics, typing these components with `forwardRef` is
 * challenging.
 * */
export default function useSelectStyles<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  overrides?: StylesConfig<Option, IsMulti, Group>,
  themingProps?: SelectThemingProps,
): Pick<Props<Option, IsMulti, Group>, "styles" | "components"> {
  const theme = useTheme();
  const inputStyles = useMultiStyleConfig("Input", {
    variant: "outline",
    ...themingProps,
  });

  const getTransparency = makeGetTransparency(theme);

  const styles = useMemo<StylesConfig<Option, IsMulti, Group>>(() => {
    const css = toCSSObject({ baseStyle: inputStyles })({
      theme,
    }) as Record<string, CSSObject>;

    const { field } = css;

    const styleKeys = Object.keys(field) as unknown as Array<keyof CSSObject>;
    const [normalStyles, pseudoStyles, variables] = styleKeys.reduce<
      [CSSObject, Partial<Record<PseudoProps, CSSObject>>, CSSObject]
    >(
      (acc, key) => {
        const value = field[key];
        if (typeof key !== "string") return acc;

        if (key.startsWith("--")) {
          acc[2][key] = value;
          return acc;
        }

        const pseudoKey = pseudoKeys.find((p) =>
          key.includes(pseudoPropsByLookup[p]),
        );

        if (pseudoKey) {
          acc[1][pseudoKey] = value as CSSObject;
        } else if (!key.includes("&")) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc[0][key] = value;
        }

        return acc;
      },
      [{}, {}, {}],
    );

    const inputBorderProps = pickBy<CSSObject>(normalStyles, isBorderProp);

    const inputFontProps = pickBy<CSSObject>(normalStyles, isFontProp);

    const { paddingY, minHeight, clearIndicatorSize } = (() => {
      if (themingProps?.size === "xs") {
        return {
          paddingY: "2px",
          minHeight: 32,
          clearIndicatorSize: 17,
        };
      }

      if (themingProps?.size === "sm") {
        return {
          paddingY: "4px",
          minHeight: 34,
          clearIndicatorSize: 19,
        };
      }

      return {
        paddingY: "7px",
        minHeight: 36,
        clearIndicatorSize: 20,
      };
    })();

    const stylesConfig: StylesConfig<Option, IsMulti, Group> = {
      ...overrides,
      menu: makeGetStylesWithOverride(
        (provided) => ({ ...provided, zIndex: 4 }),
        overrides?.menu,
      ),
      menuPortal: makeGetStylesWithOverride(
        (provided) => ({
          ...provided,
          zIndex: 1500,
          fontSize: variables["--input-font-size"] as string,
        }),
        overrides?.menuPortal,
      ),
      control: makeGetStylesWithOverride(
        (provided, state) => ({
          ...variables,
          ...omitBy(provided, isBorderProp),
          ...inputBorderProps,
          ...inputFontProps,
          minHeight,
          lineHeight:
            normalStyles.lineHeight ?? "var(--chakra-lineHeights-base)",
          transition: normalStyles.transition,
          ...(state.selectProps["aria-invalid"]
            ? pseudoStyles.invalid
            : undefined),
          ...(state.isFocused ? pseudoStyles.focus : undefined),
          ...(state.isDisabled ? pseudoStyles.disabled : undefined),
          "&:hover": pseudoStyles.hover,
        }),
        overrides?.control,
      ),
      dropdownIndicator: makeGetStylesWithOverride(
        (provided, state) => ({
          ...provided,
          alignSelf: "stretch",
          alignItems: "center",
          color: theme.colors.white,
          backgroundColor: inputBorderProps.borderColor,
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
          transition: normalStyles.transition,
          "&:hover": {
            color: theme.colors.white,
            backgroundColor: pseudoStyles.hover?.borderColor,
          },
          ...(state.isFocused
            ? {
                backgroundColor: pseudoStyles.hover?.borderColor,
              }
            : undefined),
        }),
        overrides?.dropdownIndicator,
      ),
      option: makeGetStylesWithOverride(
        (provided, state) => ({
          ...provided,
          ...(state.isFocused
            ? { backgroundColor: theme.colors.gray[100] }
            : undefined),
          ...(state.isSelected
            ? { backgroundColor: theme.colors.lightPink }
            : undefined),
          "&:active": {
            color: theme.colors.white,
            backgroundColor: theme.colors.magenta.dark,
          },
        }),
        overrides?.option,
      ),
      multiValue: makeGetStylesWithOverride(
        (provided) => ({
          ...provided,
          backgroundColor: getTransparency("softBlue", 0.35),
        }),
        overrides?.multiValue,
      ),
      multiValueRemove: makeGetStylesWithOverride(
        (provided) => ({
          ...provided,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          ":hover": {
            ...provided[":hover"],
            backgroundColor: getTransparency("red.200", 0.75),
            color: getColor(theme, "red.600"),
            transition: "background-color 100ms ease, color 100ms ease",
          },
        }),
        overrides?.multiValueRemove,
      ),
      singleValue: makeGetStylesWithOverride(
        (provided) => ({ ...provided, color: "inherit" }),
        overrides?.singleValue,
      ),
      input: makeGetStylesWithOverride(
        (provided) => ({ ...provided, color: "inherit" }),
        overrides?.input,
      ),
      clearIndicator: makeGetStylesWithOverride((provided) => ({
        ...provided,
        paddingTop: 4,
        paddingBottom: 4,
        "> svg": { width: clearIndicatorSize, height: clearIndicatorSize },
      })),
      valueContainer: makeGetStylesWithOverride(
        (provided, state) => ({
          ...provided,
          padding: state.isMulti
            ? `${paddingY} 8px`
            : `${paddingY} 2px ${paddingY} calc(${String(
                normalStyles.paddingInlineStart ?? "",
              )} - 2px)`,
        }),
        overrides?.valueContainer,
      ),
    };

    return stylesConfig;
  }, [theme, themingProps?.size, inputStyles, overrides, getTransparency]);

  const components: Props<Option, IsMulti, Group>["components"] = useMemo(
    () => ({ ...defaultComponents }),
    [],
  );

  return { styles, components };
}
