import { useRef } from "react";
import { Modal, ModalContent, ModalHeader } from "@chakra-ui/react";
import {
  useCurrentBrandData,
  useCurrentBrandId,
  useCurrentUserData,
} from "utils/currentUserHooks";
import ModalBlurOverlay from "components/Shared/ModalBlurOverlay";
import NoAccountModalBody from "components/Layout/BrandSettings/NoAccountModal/Body";

export default function NoAccountModal() {
  const currentBrandId = useCurrentBrandId();
  const currentUser = useCurrentUserData();
  const currentBrand = useCurrentBrandData();

  const initialFocusRef = useRef(null);

  const isCurrentBrandInvalid =
    !!currentUser && (!currentBrandId || currentBrand === null);

  return (
    <Modal
      isOpen={isCurrentBrandInvalid}
      onClose={() => {}}
      initialFocusRef={initialFocusRef}
    >
      <ModalBlurOverlay />
      <ModalContent>
        <ModalHeader color="magenta.veryDark">Choose an Account</ModalHeader>
        <NoAccountModalBody initialFocusRef={initialFocusRef} />
      </ModalContent>
    </Modal>
  );
}
