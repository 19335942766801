import { defineStyleConfig } from "@chakra-ui/react";
import commonStyles from "./commonFormStyles";

export default defineStyleConfig({
  ...commonStyles,
  sizes: {
    ...commonStyles.sizes,
    md: { ...commonStyles.sizes?.md.field, py: 3 },
  },
});
