/* eslint no-underscore-dangle: ["error", { "allow": ["_title"] }] */

export type UserErrorStatus = "error" | "warning" | "info";

export type UserErrorOptions = {
  code?: string | number;
  description?: string;
  helpText?: string;
  status?: UserErrorStatus;
  title?: string | null;
};

export default class UserError extends Error {
  public code: UserErrorOptions["code"];

  public description: UserErrorOptions["description"];

  public helpText: UserErrorOptions["helpText"];

  public status: UserErrorStatus;

  private _title: UserErrorOptions["title"];

  constructor(message: string, options?: UserErrorOptions) {
    super(message);

    this.code = options?.code;
    this.description = options?.description;
    this.helpText = options?.helpText;
    this.status = options?.status || "error";
    this._title = options?.title;

    Object.setPrototypeOf(this, UserError.prototype);
  }

  get title() {
    if (this._title === null) return null;
    return this._title ?? this.message;
  }
}
