import { ReactNode } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import { Button, Text } from "@chakra-ui/react";
import ErrorPage, { Props as BaseProps } from "components/Shared/ErrorPage";
import UserError from "utils/UserError";

type Props = BaseProps & {
  children: ReactNode;
  isWithNavBar?: boolean;
};

export default function ErrorBoundary({
  children,
  isWithNavBar,
  ...rest
}: Props) {
  return (
    <SentryErrorBoundary
      // eslint-disable-next-line @typescript-eslint/unbound-method
      fallback={({ error, eventId, resetError }) => (
        <ErrorPage
          heading="Oh no!"
          subheading="An unexpected error occurred"
          isWithNavBar={isWithNavBar}
          {...rest}
        >
          {error instanceof UserError && (
            <Text
              mt={16}
              fontSize="2xl"
              backgroundColor="rgba(255, 255, 255, 0.4)"
              borderRadius="lg"
              p={4}
            >
              {error.message}
            </Text>
          )}

          {eventId && (
            <>
              <Text mt={16}>Our engineers have been notified.</Text>
              <Text fontSize="xs">
                Event ID: <code>{eventId}</code>
              </Text>
            </>
          )}

          <Button
            onClick={() => resetError()}
            colorScheme="lightPinkButton"
            size="lg"
            mt={8}
          >
            Reload
          </Button>
        </ErrorPage>
      )}
    >
      {children}
    </SentryErrorBoundary>
  );
}
