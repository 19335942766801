import { useCallback, useEffect, useState } from "react";
import { captureException } from "@sentry/nextjs";
import {
  Button,
  ButtonProps,
  Text,
  ToastId,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";

const INTERVAL = 5 * 60 * 1000;

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  position: "top",
  duration: null,
  isClosable: true,
  variant: "subtle",
  containerStyle: { width: "31rem", maxWidth: "75vw" },
};

type ReleaseCheckData = {
  intervalId?: NodeJS.Timer;
  toastId?: ToastId;
};

const data: ReleaseCheckData = {};

async function fetchLatestRelease() {
  const response = await window.fetch("/api/release");
  const text = await response.text();
  return text.trim();
}

function endInterval() {
  if (!data.intervalId) return;
  clearInterval(data.intervalId);
  data.intervalId = undefined;
}

function ReloadButton({
  children = "Reload",
  ...rest
}: Omit<ButtonProps, "onClick">) {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Button
      type="button"
      {...rest}
      onClick={() => {
        setIsClicked(true);
        window.location.reload();
      }}
      isLoading={isClicked}
    >
      {children}
    </Button>
  );
}

export default function useReleaseCheck() {
  const showToast = useToast(DEFAULT_TOAST_OPTIONS);

  const performCheck = useCallback(() => {
    if (data.toastId) return;

    const currentRelease = process.env.NEXT_PUBLIC_GIT_SHA;

    fetchLatestRelease()
      .then((latestRelease) => {
        if (
          currentRelease &&
          latestRelease &&
          currentRelease !== latestRelease &&
          !data.toastId
        ) {
          data.toastId = showToast({
            title: "Newer Version Available!",
            description: (
              <>
                <Text>
                  A newer version of the Pitchable application has been
                  released. Please save any open work and reload this browser
                  tab to take advantage of the latest improvements and ensure
                  full compatibility.
                </Text>
                <Text textAlign="center" mt={2}>
                  <ReloadButton colorScheme="pinkButton" />
                </Text>
              </>
            ),
            onCloseComplete: () => {
              data.toastId = undefined;
            },
          });
        }
      })
      .catch((reason) => {
        captureException(reason);
      });
  }, [showToast]);

  const handleInterval = useCallback(() => {
    if (document.hidden) {
      endInterval();
    } else {
      performCheck();
    }
  }, [performCheck]);

  const startInterval = useCallback(() => {
    data.intervalId = setInterval(handleInterval, INTERVAL);
  }, [handleInterval]);

  const handleFocus = useCallback(() => {
    if (data.intervalId) return;
    performCheck();
    startInterval();
  }, [performCheck, startInterval]);

  useEffect(() => {
    if (typeof window === "undefined" || process.env.NODE_ENV !== "production")
      return;

    startInterval();
    window.addEventListener("focus", handleFocus);

    return () => {
      endInterval();
      window.removeEventListener("focus", handleFocus);
    };
  }, [handleFocus, startInterval]);
}
