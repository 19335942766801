import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import { getLabeledValueStyles } from "./Stat";

const msch = createMultiStyleConfigHelpers(parts.keys);

const commonCollapsedCellStyles = defineStyle({
  px: 2,
  py: 1,
  _first: { pl: 0 },
  _last: { pr: 0 },
  "&[data-is-numeric=true]": {
    textAlign: "end",
    fontVariantNumeric: "tabular-nums",
  },
});

const collapsed = msch.definePartsStyle({
  th: {
    ...commonCollapsedCellStyles,
    fontSize: "0.9em",
    pb: 1,
  },
  td: {
    ...commonCollapsedCellStyles,
  },
  tfoot: {
    td: { fontWeight: 600 },
  },
});

const rowBordersTBody = defineStyle({
  "> tr": {
    borderBottomWidth: "1px",
    "&:last-of-type": {
      borderBottomWidth: 0,
    },
  },
});

const brand = msch.definePartsStyle({
  thead: {
    backgroundColor: "softBlue",
    tr: { backgroundColor: "inherit" },
    th: {
      color: "white",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      '&[colspan]:not([colspan="1"])': {
        position: "relative",
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        "&::after": {
          content: '" "',
          display: "block",
          position: "absolute",
          left: "5px",
          right: "5px",
          bottom: 0,
          height: "2px",
          backgroundColor: "white",
        },
      },
    },
  },
  th: {
    backgroundColor: "inherit",
    textTransform: "none",
    px: 4,
    fontSize: "1em",
    pt: 2,
    pb: 3,
  },
  td: {
    px: 4,
    "&[data-is-numeric=true]": {
      textAlign: "end",
      fontVariantNumeric: "tabular-nums",
    },
  },
  tbody: rowBordersTBody,
  tfoot: {
    fontWeight: 600,
    borderTopWidth: 1,
    backgroundColor: "inherit",
  },
});

export default msch.defineMultiStyleConfig({
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    brand,
    condensed: msch.definePartsStyle({
      ...brand,
      table: { fontSize: "sm" },
      th: { ...brand.th, px: 3 },
      td: { ...brand.td, py: 1.5, px: 3 },
    }),
    collapsed,
    labeledValue: msch.definePartsStyle({
      ...collapsed,
      thead: {
        th: { borderBottomWidth: 3, borderBottomColor: "magenta.dark" },
      },
      th: {
        fontFamily: "body",
        letterSpacing: "normal",
        ...commonCollapsedCellStyles,
        ...getLabeledValueStyles()?.label,
      },
      tbody: rowBordersTBody,
      tfoot: {
        ...collapsed.tfoot,
        td: {
          ...collapsed.tfoot.td,
          color: "gray.600",
          borderTopWidth: 3,
          borderTopColor: "magenta.dark",
        },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: { variant: "brand" },
});
