import { ModalOverlay, ModalOverlayProps } from "@chakra-ui/react";

export default function ModalBlurOverlay(props: ModalOverlayProps) {
  return (
    <ModalOverlay
      backdropFilter="blur(5px)"
      background="blackAlpha.300"
      {...props}
    />
  );
}
