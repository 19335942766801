import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import tokenService from "utils/TokenService";
import { SERVER_PREFIX } from "utils/config";

type HeadersContext = {
  headers?: {
    [header: string]: string | undefined;
  };
};

const authLink = setContext(
  async (_, { headers }: HeadersContext): Promise<HeadersContext> => {
    const authHeaders = await tokenService.getAuthorizationHeaders("JWT");

    // get the authentication token from local storage if it exists
    const csrfToken = Cookies.get("csrftoken");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...authHeaders,
        "X-CSRFToken": csrfToken,
        ...headers,
      },
    };
  },
);

const link = createHttpLink({
  uri: `${SERVER_PREFIX}/graphql/`,

  // TODO: make this more compatible with Django's auth without `omit` here.
  // Don't send Django's built-in cookies (rely exclusively on JWTs), otherwise
  // we risk masking authentication errors in development since Django's admin
  // shares the same cookie domain as the application. Normal application users,
  // however, cannot login to the Django admin, so will never have these
  // cookies.
  credentials: "omit",
});

const serverClient = new ApolloClient({
  link: authLink.concat(link) as unknown as ApolloLink,
  cache: new InMemoryCache(),
  connectToDevTools: false,
  defaultOptions: {
    query: { fetchPolicy: "no-cache" },
    watchQuery: { fetchPolicy: "no-cache" },
    mutate: { fetchPolicy: "no-cache" },
  },
});

export default serverClient;
