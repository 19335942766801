import { UseToastOptions } from "@chakra-ui/react";
import UserError from "utils/UserError";

function getMessage(messageOrOptions?: string | UseToastOptions) {
  if (messageOrOptions) {
    if (typeof messageOrOptions === "string") return messageOrOptions;

    if (typeof messageOrOptions.title === "string")
      return messageOrOptions.title;

    if (typeof messageOrOptions.description === "string")
      return messageOrOptions.description;
  }
  return "An unknown error occurred";
}

export default class ToastError extends UserError {
  private providedToastOptions: UseToastOptions | undefined;

  constructor(options: UseToastOptions);
  constructor(message?: string, options?: UseToastOptions);
  constructor(
    messageOrOptions?: string | UseToastOptions,
    options?: UseToastOptions,
  ) {
    super(getMessage(messageOrOptions));

    this.providedToastOptions =
      typeof messageOrOptions === "string" ? options : messageOrOptions;

    Object.setPrototypeOf(this, ToastError.prototype);
  }

  get toastOptions(): UseToastOptions {
    return {
      status: this.status,
      description: this.message,
      ...this.providedToastOptions,
    };
  }
}
