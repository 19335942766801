export const ENV_BY_HOSTNAME = {
  "pitchable.rodeocpg.com": "prod",
  "app.rodeocpg-dev.net": "dev",
};

function getEnv() {
  const { hostname } = window.location;
  return hostname && ENV_BY_HOSTNAME[hostname];
}

export default getEnv;
