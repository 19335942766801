/*
 * Components
 *
 * You can overwrite or add to the styling of existing components. This
 * is a lot easier than building your own. The most common use-case is
 * to add a variant of an existing component, like adding a new type
 * of button that exists in the designs but not in Chakra.
 *
 * You can see how this is setup in the base theme here:
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/index.ts
 *
 */

import Alert from "./Alert";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Drawer from "./Drawer";
import Form from "./Form";
import FormLabel from "./FormLabel";
import Heading from "./Heading";
import Input from "./Input";
import Link from "./Link";
import Menu from "./Menu";
import NumberInput from "./NumberInput";
import Radio from "./Radio";
import Slider from "./Slider";
import Table from "./Table";
import Text from "./Text";
import Textarea from "./Textarea";
import Select from "./Select";
import Stat from "./Stat";
import Switch from "./Switch";

const components = {
  Alert,
  Button,
  Checkbox,
  Drawer,
  Form,
  FormLabel,
  Heading,
  Input,
  Link,
  Menu,
  NumberInput,
  Radio,
  Slider,
  Table,
  Text,
  Textarea,
  Select,
  Stat,
  Switch,
};

export default components;
