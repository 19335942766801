/*
 * Use this file for setting global theme styles like the font(s),
 * base-text color, and the background color of the body.
 *
 * You can see the default values here:
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts
 */
const styles = {
  global: {
    html: {
      WebkitFontSmoothing: "auto",
      MozOsxFontSmoothing: "auto",
    },
    "body > #date-picker .react-datepicker-popper": {
      zIndex: 100,
    },
  },
};

export default styles;
