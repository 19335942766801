import {
  darken,
  StyleFunctionProps,
  transparentize,
} from "@chakra-ui/theme-tools";
import commonStyles, { getVariant, msch } from "./commonFormStyles";

export default msch.defineMultiStyleConfig({
  // Styles for the base style
  baseStyle: {
    field: commonStyles.baseStyle,
  },
  // Styles for the size variations
  sizes: {
    ...commonStyles.sizes,
    lg: { field: { ...commonStyles.sizes?.lg, h: 14 }, addon: { h: 14 } },
    md: { field: { ...commonStyles.sizes?.md, h: 12 }, addon: { h: 12 } },
  },
  // Styles for the visual style variations
  variants: {
    outline: (props: StyleFunctionProps) => ({
      field: getVariant("outline", props),
      addon: {
        borderColor: "softBlue",
        backgroundColor: transparentize("softBlue", 0.1)(props.theme),
        color: darken("softBlue", 5)(props.theme),
      },
    }),
    flushed: (props: StyleFunctionProps) => ({
      field: getVariant("flushed", props),
    }),
  },
  // The default `size` or `variant` values
  defaultProps: commonStyles.defaultProps,
});
