import {
  ChakraTheme,
  createMultiStyleConfigHelpers,
  SystemStyleObject,
  defineStyleConfig,
  ThemingPropsThunk,
  defineStyle,
} from "@chakra-ui/react";
import { getColorVar, StyleFunctionProps } from "@chakra-ui/theme-tools";
// eslint-disable-next-line import/no-extraneous-dependencies
import { inputAnatomy as parts } from "@chakra-ui/anatomy";

export const msch = createMultiStyleConfigHelpers(parts.keys);

const BORDER_RADIUS = 10;

const baseStyle: ThemingPropsThunk<SystemStyleObject, ChakraTheme> = {
  borderRadius: BORDER_RADIUS,
  fontWeight: 300,
  bg: "white",
};

const sizes = {
  "3xl": msch.definePartsStyle({
    field: { fontSize: "3xl", fontFamily: "heading" },
  }),
  "2xl": msch.definePartsStyle({
    field: { fontSize: "2xl", fontFamily: "heading" },
  }),
  xl: msch.definePartsStyle({ field: { fontSize: "xl" } }),
  lg: msch.definePartsStyle({ field: { borderRadius: BORDER_RADIUS } }),
  md: msch.definePartsStyle({ field: { borderRadius: BORDER_RADIUS } }),
  sm: msch.definePartsStyle({ field: { borderRadius: BORDER_RADIUS } }),
  xs: msch.definePartsStyle({ field: { borderRadius: BORDER_RADIUS } }),
};

const variants = {
  outline: (props: StyleFunctionProps): SystemStyleObject => ({
    bg: baseStyle.bg,
    borderColor: "softBlue",
    _hover: { borderColor: "lightPink" },
    _focus: {
      borderColor:
        (props.focusBorderColor as string | undefined) || "lightPink",
      boxShadow: `0 0 0 1px ${
        getColorVar(
          props.theme,
          (props.focusBorderColor as string | undefined) || "lightPink",
        ) as string
      }`,
    },
  }),
  flushed: (props: StyleFunctionProps): SystemStyleObject => ({
    borderColor: "softBlue",
    _focus: {
      borderColor:
        (props.focusBorderColor as string | undefined) || "lightPink",
    },
  }),
};

export function getVariant(
  name: keyof typeof variants,
  props: StyleFunctionProps,
): SystemStyleObject {
  const variant = variants[name];
  if (typeof variant === "function") return variant(props);
  return variant;
}

const defaultProps = defineStyle({
  size: "md",
} as const);

const common = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps,
});

export default common;
