import { MutableRefObject, useCallback } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Icon,
  ModalBody,
  ModalFooter,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { MdLogout, MdNoAccounts } from "react-icons/md";
import {
  useCurrentUserData,
  useFetchCurrentUser,
  useSetCurrentBrandId,
} from "utils/currentUserHooks";
import AccountSelection from "components/Shared/AccountSelection";
import ContactEmailLink from "components/Shared/ContactEmailLink";
import { useLogout } from "utils/auth";
import asyncVoidHandler from "utils/asyncVoidHandler";
import EmailLink from "components/Shared/EmailLink";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialFocusRef?: MutableRefObject<any>;
};

export default function NoAccountModalBody({ initialFocusRef }: Props) {
  const [logout, { loading: isLoggingOut }] = useLogout();
  const currentUser = useCurrentUserData();
  const { data: currentUserData } = useFetchCurrentUser({ skip: !currentUser });
  const setCurrentBrandId = useSetCurrentBrandId();

  const handleBrandChange = useCallback(
    (brandId: string | null) => {
      setCurrentBrandId(brandId).catch(() => {});
    },
    [setCurrentBrandId],
  );

  if (!currentUser) {
    return (
      <ModalBody textAlign="center" pt={8} pb={10}>
        <Spinner size="xl" color="gray.500" thickness="3px" />
      </ModalBody>
    );
  }

  const isNoBrands = currentUserData?.brands?.totalCount === 0;

  return (
    <>
      <ModalBody>
        {isNoBrands ? (
          <Alert status="error" display="block">
            <AlertTitle display="flex">
              <AlertIcon as={MdNoAccounts} /> No Brand Access
            </AlertTitle>
            <AlertDescription
              lineHeight="normal"
              sx={{ "a:not(.chakra-button)": { color: "var(--alert-fg)" } }}
            >
              <Text my={4}>
                The user account associated with{" "}
                <b>
                  <EmailLink email={currentUser?.email} />
                </b>{" "}
                does not have access to any brands in Pitchable.
              </Text>
              <Text>
                If you believe this is an error, please contact us at{" "}
                <ContactEmailLink />.
              </Text>
            </AlertDescription>
          </Alert>
        ) : (
          <AccountSelection
            isIncludeInactiveShown
            initialFocusRef={initialFocusRef}
            autoFocus
            onBrandSelect={handleBrandChange}
          />
        )}
      </ModalBody>

      <ModalFooter gap={2}>
        <Box fontSize="xs" color="gray.500" isTruncated>
          <Box fontWeight={500}>Logged in as:</Box>
          <EmailLink email={currentUser?.email} tabIndex={-1} />
        </Box>
        <Button
          variant="outline"
          size="sm"
          leftIcon={<Icon as={MdLogout} />}
          onClick={asyncVoidHandler(logout)}
          isLoading={isLoggingOut}
          ref={isNoBrands ? initialFocusRef : undefined}
          autoFocus={isNoBrands || undefined}
          ml="auto"
        >
          Sign Out
        </Button>
      </ModalFooter>
    </>
  );
}
