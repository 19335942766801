import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { OptionProps, components } from "react-select";

type RenderSubtitleProps<Option = unknown> = { option: Option };

type MakeSubtitleOptionComponentOptions<Option = unknown> = {
  isLabelTruncated?: boolean;
  renderSubtitle: (props: RenderSubtitleProps<Option>) => ReactNode;
};

export default function makeSubtitleOptionComponent<Option = unknown>({
  isLabelTruncated,
  renderSubtitle,
}: MakeSubtitleOptionComponentOptions<Option>) {
  // eslint-disable-next-line react/function-component-definition
  return function CustomOption<IsMulti extends boolean = false>({
    children,
    innerProps,
    ...rest
  }: OptionProps<Option, IsMulti>) {
    const { isSelected, data } = rest;
    const subtitle = renderSubtitle?.({ option: data });
    return (
      <components.Option
        {...rest}
        innerProps={{ ...innerProps, "aria-selected": isSelected }}
        isSelected={isSelected}
      >
        <Box isTruncated={isLabelTruncated}>{children}</Box>
        {subtitle && (
          <Box opacity={0.75} fontWeight={300} fontSize=".8em">
            {subtitle}
          </Box>
        )}
      </components.Option>
    );
  };
}
