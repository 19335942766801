const store: { [key: string]: unknown } = {};

class EphemeralBrandStorage<V> {
  public key: string;

  constructor(key: string) {
    this.key = key;
  }

  set(value: V) {
    store[this.key] = value;
  }

  get() {
    return store[this.key] as V | undefined;
  }
}

export default function ephemeralBrandStorage<V>(key: string) {
  return new EphemeralBrandStorage<V>(key);
}

export function resetEphemeralBrandStorage() {
  Object.keys(store).forEach((key) => {
    delete store[key];
  });
}
