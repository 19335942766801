import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { formAnatomy as parts } from "@chakra-ui/anatomy";

const msch = createMultiStyleConfigHelpers(parts.keys);

export default msch.defineMultiStyleConfig({
  baseStyle: {
    helperText: {
      fontSize: "xs",
      mt: -2,
      mb: 2,
    },
  },
});
