import { ForwardedRef, forwardRef, useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import type { SelectInstance } from "react-select";
import AsyncSelect from "react-select/async";
import useSelectStyles from "utils/useSelectStyles";
import { formatUrlAsDomain } from "utils/formatting";
import { gql } from "__generated__/gql";
import {
  BrandFilter,
  SwitchAccountOptionsQuery,
} from "__generated__/gql/graphql";
import { useCurrentBrandId } from "utils/currentUserHooks";
import makeSubtitleOptionComponent from "components/Shared/Select/makeSubtitleOptionComponent";
import useAsyncFetchOptions from "components/Shared/Select/useAsyncFetchOptions";
import useLatestRef from "utils/useLatestRef";

const query = gql(/* GraphQL */ `
  query SwitchAccountOptions($filter: BrandFilter!) {
    brands(filter: $filter, first: 5) {
      nodes {
        id
        name
        website
      }
    }
  }
`);

type Option = NonNullable<SwitchAccountOptionsQuery["brands"]>["nodes"][number];

const CustomOption = makeSubtitleOptionComponent<Option>({
  renderSubtitle: ({ option }) => {
    if (!option.website) return null;
    return formatUrlAsDomain(option.website);
  },
});

function getOptionLabel(option: Option) {
  return option.name;
}

function getOptionValue(option: Option) {
  return option.id;
}

function noOptionsMessage({ inputValue }: { inputValue: string }) {
  if (inputValue?.length) return "No accounts found";
  return "Type to search for accounts…";
}

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming
  autoFocus?: boolean;
  filter?: BrandFilter;
  onBrandChange: (brandId: string | null) => void;
};

function SwitchAccountSelect(
  { autoFocus = true, filter: filterProp, onBrandChange }: Props,
  ref: ForwardedRef<SelectInstance<Option, false>>,
) {
  const client = useApolloClient();
  const styleProps = useSelectStyles<Option, false>();
  const currentBrandId = useCurrentBrandId();

  const latestFilterPropRef = useLatestRef(filterProp);

  const asyncFetchProps = useAsyncFetchOptions({
    cacheUsing: filterProp,
    fetchOptions: useCallback(
      (inputValue) => {
        const baseFilter: BrandFilter = {
          name: { includesInsensitive: inputValue },
        };
        const filter: BrandFilter = latestFilterPropRef.current
          ? { and: [baseFilter, latestFilterPropRef.current] }
          : baseFilter;
        return client
          .query({ query, variables: { filter }, fetchPolicy: "no-cache" })
          .then(({ data }) => {
            const brands = data.brands?.nodes;
            if (!brands) throw new Error("Could not get brands from response.");
            return brands;
          });
      },
      [latestFilterPropRef, client],
    ),
    minInputLength: 1,
  });

  const handleChange = useCallback(
    (option: Option | null) => {
      onBrandChange(option?.id || null);
    },
    [onBrandChange],
  );

  return (
    <AsyncSelect<Option, false>
      ref={ref}
      {...styleProps}
      {...asyncFetchProps}
      {...{ getOptionLabel, getOptionValue }}
      placeholder="Search for an account…"
      noOptionsMessage={noOptionsMessage}
      components={{ ...styleProps.components, Option: CustomOption }}
      onChange={handleChange}
      isOptionSelected={(option) => option.id === currentBrandId}
      autoFocus={autoFocus}
    />
  );
}

export default forwardRef(SwitchAccountSelect);
