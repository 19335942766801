import { ReactNode } from "react";
import { Heading, Text, Container } from "@chakra-ui/react";
import CactusBackground, {
  Props as BaseProps,
} from "components/Shared/CactusBackground";

export type Props = Omit<BaseProps, "children"> & {
  children?: ReactNode;
  heading?: ReactNode;
  subheading?: ReactNode;
};

function ErrorPage({
  children,
  graphicsProps,
  heading,
  isWithNavBar,
  subheading,
  ...rest
}: Props) {
  return (
    <CactusBackground
      isWithNavBar={isWithNavBar}
      graphicsProps={{
        sx: {
          "@media (max-height: 700px), (max-width: 740px)": {
            opacity: "0.4",
          },
        },
        ...graphicsProps,
      }}
      {...rest}
    >
      <Container
        textAlign="center"
        p="10vh 1rem 1rem 1rem"
        color="magenta.veryDark"
        pos="relative"
      >
        {heading && <Heading fontSize="9xl">{heading}</Heading>}
        {subheading && (
          <Text fontSize="4xl" fontWeight="700" mt={6}>
            {subheading}
          </Text>
        )}
        {children}
      </Container>
    </CactusBackground>
  );
}

export default ErrorPage;
