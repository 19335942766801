import { defineStyleConfig } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button as ButtonBase } from "@chakra-ui/theme/components";

export default defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    fontFamily: "Gotham",
    fontWeight: 300,
    textTransform: "uppercase",
    borderRadius: "full",
    px: "12",
    cursor: "pointer",
  },
  // Styles for the size variations
  sizes: {
    xs: { minW: "auto" },
    sm: { minW: "auto" },
    md: { h: "auto", px: 12, py: 3, minW: "auto" },
    lg: { h: "auto", px: 14, py: 6, minW: "auto" },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: "md",
  },
  variants: {
    // Chakra v2 specifies a color for gray buttons, which is the default
    // colorScheme for buttons. Chakra v1 simply let gray buttons inherit their
    // color, so these `solid` and `ghost` overrides attempt to reverse that
    // change to keep the behavior consistent for all the buttons in the app
    // that have not specified a colorScheme.
    // TODO: the buttons themselves should be fixed instead of this:
    solid: (props) => {
      if (props.colorScheme === "gray") {
        return { color: "inherit" };
      }
      return {};
    },
    ghost: (props) => {
      if (props.colorScheme === "gray") {
        return { color: "inherit" };
      }
      return {};
    },
    simpleSquare: (props) => ({
      ...ButtonBase.variants?.solid(props),
      borderRadius: 0,
      textTransform: "none",
      _checked: { bg: `${props.colorScheme}.checked` },
    }),
    link: {
      borderRadius: 0,
      fontFamily: "body",
      fontWeight: "inherit",
      textTransform: "inherit",
    },
  },
});
