import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { drawerAnatomy as parts } from "@chakra-ui/anatomy";

const msch = createMultiStyleConfigHelpers(parts.keys);

export default msch.defineMultiStyleConfig({
  baseStyle: {
    dialogContainer: {
      w: "25vw",
      position: "unset",
      top: "105px",
      height: "unset",
    },
    body: {},
  },
  // Styles for the size variations
  sizes: {
    xs: {
      dialog: { w: "20%" },
    },
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
});
