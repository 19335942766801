import Head from "next/head";

export default function Favicon() {
  return (
    <Head>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=2"
      />
      <link rel="manifest" href="/site.webmanifest?v=2" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#5bbad5" />
      <link rel="icon" href="/favicon.ico?v=2" />
      <meta name="apple-mobile-web-app-title" content="Pitchable" />
      <meta name="application-name" content="Pitchable" />
      <meta name="msapplication-TileColor" content="#9f00a7" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
}
