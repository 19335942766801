export const NAVBAR_HEIGHT = "4rem";

export const SIDEBAR_WIDTH = "15.75rem";

export const CONTENT_FULL_HEIGHT = `calc(100vh - ${NAVBAR_HEIGHT})`;

export const CONTENT_FULL_HEIGHT_PROPS = {
  height: `calc(100% - ${NAVBAR_HEIGHT})`,
  mt: NAVBAR_HEIGHT,
} as const;
