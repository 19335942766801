/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */
import {
  ApolloClient,
  ApolloError,
  createHttpLink,
  InMemoryCache,
  isApolloError,
  NormalizedCacheObject,
} from "@apollo/client";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { captureException } from "@sentry/nextjs";
import { REFRESH_TOKEN, REVOKE_TOKEN } from "./authQueries";
import { SERVER_PREFIX } from "./config";

if (!process.browser) {
  // 'Polyfill' EventTarget in SSR
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.EventTarget = Object;
}

class UnauthenticatedError extends Error {
  isUnauthenticated = true;
}

class JwtError extends Error {}

export type TokenPayload = {
  email: string;
  exp: number;
  origIat: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "https://rodeocpg.io/isStaff": boolean;
};

function isTimestampExpired(time: number, leeway = 0): boolean {
  const currentTime = new Date().getTime() / 1000;
  return currentTime > time + leeway;
}

export class Token {
  raw: string;

  private _payload?: TokenPayload;

  constructor(raw: string) {
    this.raw = raw;
  }

  get payload() {
    if (!this._payload) {
      this._payload = jwtDecode<TokenPayload>(this.raw);
    }
    return this._payload;
  }

  get isExpired() {
    return this.getIsExpired();
  }

  get isStaff() {
    return this.payload["https://rodeocpg.io/isStaff"];
  }

  getIsExpired(leeway?: number) {
    if (!this.payload.exp) {
      throw new JwtError("Token is missing `exp` claim");
    }
    return isTimestampExpired(this.payload.exp, leeway);
  }
}

function getIsUnauthenticatedError(
  error: Error | undefined | null,
): error is UnauthenticatedError {
  if (!error) return false;
  return !!Object.prototype.hasOwnProperty.call(error, "isUnauthenticated");
}

function getIsInvalidRefreshTokenError(error?: ApolloError | Error) {
  if (!error || !isApolloError(error)) return false;
  return (
    error.message === "Invalid refresh token" ||
    error.message === "Refresh token is expired"
  );
}

export const AUTH_STATUS_CHANGE_EVENT = "authStatusChange";

export const AUTH_TOKEN_CHANGE_EVENT = "authTokenChange";

class TokenService extends EventTarget {
  tokenObj?: Token;

  private _isAuthenticated: boolean;

  private refreshToken?: string;

  private readonly refreshTokenCookieName = "refresh_token";

  private _apolloClient?: ApolloClient<NormalizedCacheObject>;

  private refreshingPromise?: Promise<string>;

  constructor() {
    super();

    /** Initialize the service with the refresh token cookie. */
    this.refreshToken = this.refreshTokenCookie;

    /**
     * On initialization, we detemine current authentication status based on the
     * presence of a refresh token cookie. This is 'optimistic' since most
     * likely if the user has a refresh token cookie then it has not expired,
     * and has not been revoked.
     *
     * Of course, this does not guarantee that the refresh_token is valid, but
     * we will only know its validity after the query to refresh the JWT. Worst
     * case: we incorrectly assume the user is authenticated, despite having an
     * invalid refresh token, and wait a second longer to redirect said user to
     * the login page than we otherwise would.
     * */
    this._isAuthenticated = !!this.refreshToken;
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }

  set isAuthenticated(isAuthenticated: boolean) {
    if (isAuthenticated !== this._isAuthenticated) {
      this._isAuthenticated = isAuthenticated;
      this.dispatchEvent(new Event(AUTH_STATUS_CHANGE_EVENT));
    }
  }

  set token(token: string | undefined) {
    this.tokenObj = token ? new Token(token) : undefined;
    this.dispatchEvent(new Event(AUTH_TOKEN_CHANGE_EVENT));
  }

  get token() {
    return this.tokenObj?.raw;
  }

  get payload() {
    return this.tokenObj?.payload;
  }

  setRefreshToken(refreshToken: string | undefined, expires?: number) {
    this.refreshToken = refreshToken;
    this.setRefreshTokenCookie(refreshToken, expires);
    this.isAuthenticated =
      !!this.refreshToken && (!expires || !isTimestampExpired(expires));
  }

  async getAuthorizationHeaders(tokenType = "Bearer") {
    try {
      const token = await this.getOrRefreshToken();
      return { Authorization: `${tokenType} ${token}` };
    } catch (e) {
      // return void if we can't get a token
    }
  }

  getOrRefreshToken(): Promise<string> {
    const { raw } = this.tokenObj || {};

    if (raw && !this.tokenObj?.getIsExpired(-5)) return Promise.resolve(raw);

    if (this.refreshingPromise) return this.refreshingPromise;

    this.refreshingPromise = this.fetchRefreshedToken()
      .then((result) => {
        const { token, refreshToken, refreshExpiresIn } =
          result?.data?.refreshToken || {};
        this.token = token;
        this.setRefreshToken(refreshToken, refreshExpiresIn);
        if (!this.token) throw new Error("No token returned");
        return this.token;
      })
      .catch((exc: Error | undefined) => {
        const error = exc || new Error("Unknown error");
        this.token = undefined;
        if (
          getIsInvalidRefreshTokenError(exc) ||
          getIsUnauthenticatedError(error)
        ) {
          this.setRefreshToken(undefined);
        } else {
          console.error(error);
          captureException(error);
        }
        throw error;
      })
      .finally(() => {
        this.refreshingPromise = undefined;
      });
    return this.refreshingPromise;
  }

  async revokeToken() {
    const { refreshToken } = this;
    if (!refreshToken) return;
    try {
      await this.apolloClient.query({
        fetchPolicy: "no-cache",
        query: REVOKE_TOKEN,
        variables: { refreshToken },
      });
      return true;
    } catch (e: unknown) {
      // If token is already invalid, just move on, otherwise re-raise the exception
      if (getIsInvalidRefreshTokenError(e as Error)) return;
      throw e;
    }
  }

  private setRefreshTokenCookie(token: string | undefined, expires?: number) {
    if (token) {
      Cookies.set(this.refreshTokenCookieName, token, {
        expires: expires && new Date(expires * 1000),
        sameSite: "strict",
        secure: true,
      });
    } else {
      Cookies.remove(this.refreshTokenCookieName);
    }
  }

  private get refreshTokenCookie() {
    return Cookies.get(this.refreshTokenCookieName) || undefined;
  }

  private async fetchRefreshedToken() {
    const { refreshToken } = this;
    if (!refreshToken) {
      return Promise.reject(
        new UnauthenticatedError("No refresh token available"),
      );
    }
    return this.apolloClient.query({
      fetchPolicy: "no-cache",
      query: REFRESH_TOKEN,
      variables: { refreshToken },
    });
  }

  /** Returns an `ApolloClient` to be used only with token queries. We cannot
   * use the regular `apollo-client` because this `TokenService` is part of it!
   * */
  private get apolloClient() {
    if (!this._apolloClient) {
      const uri = `${SERVER_PREFIX}/graphql/`;
      this._apolloClient = new ApolloClient({
        link: createHttpLink({ uri, credentials: "omit" }),
        cache: new InMemoryCache(),
      });
    }
    return this._apolloClient;
  }
}

export default new TokenService();
