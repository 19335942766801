import { FieldFunctionOptions, FieldPolicy, Reference } from "@apollo/client";

type KeyArgs = FieldPolicy["keyArgs"];

export type NodesConnection<TNode> = Readonly<{
  totalCount?: number;
  pageInfo?: Readonly<{
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  }>;
  nodes?: (TNode | undefined)[];
}>;

type Args = {
  offset?: number;
  first?: number;
};

export const DEFAULT_CONNECTION_KEY_ARGS = [
  "condition",
  "filter",
  "orderBy",
] as const;

/**
 * A pagination helper that will merge the `nodes` property of paginated calls
 * to connection fields. Note that this WILL NOT work with either non-connection
 * list fields or with connection fields that use `edges` instead of `nodes`.
 */
export default function makeNodesLimitOffsetPagination<T = Reference>(
  keyArgs: KeyArgs = DEFAULT_CONNECTION_KEY_ARGS,
): FieldPolicy<NodesConnection<T>> {
  return {
    keyArgs,

    read(existing, { args }: FieldFunctionOptions<Args>) {
      if (!existing?.nodes) return existing;

      const isExistingEqualToTotalCount =
        typeof existing.totalCount === "number" &&
        existing.totalCount === existing.nodes.length;

      const { offset = 0, first } = args || {};

      let end: number | undefined;

      if (first || first === 0) {
        end = offset + first;
      } else if (isExistingEqualToTotalCount) {
        end = existing.totalCount;
      }

      if (end === undefined || (!end && end !== 0)) return;

      if (end > existing.nodes.length && !isExistingEqualToTotalCount) {
        return;
      }

      const nodes = existing.nodes.slice(offset, end);

      if (nodes.includes(undefined)) return;

      const pageInfo = { ...existing.pageInfo };
      if (existing.nodes[end + 1]) pageInfo.hasNextPage = true;
      if (offset > 0 && existing.nodes[offset - 1])
        pageInfo.hasPreviousPage = true;

      return { ...existing, pageInfo, nodes };
    },

    merge(
      // eslint-disable-next-line @typescript-eslint/default-param-last
      existing = {},
      incoming,
      { args }: FieldFunctionOptions<Args>,
    ) {
      const { offset = 0 } = args || {};

      const nodes = existing?.nodes ? existing.nodes.slice(0) : [];

      if (incoming?.nodes) {
        for (let i = 0; i < incoming.nodes.length; i += 1) {
          nodes[offset + i] = incoming.nodes[i];
        }
      }

      const pageInfo: Required<NodesConnection<T>>["pageInfo"] = {
        // TODO: this will not work in many circumstances
        ...existing.pageInfo,
        ...incoming.pageInfo,
      };

      const result = { ...existing, ...incoming };

      if (result.nodes) {
        result.nodes = nodes;
      }

      if (result.pageInfo) {
        result.pageInfo = pageInfo;
      }

      return result;
    },
  };
}
