import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    fontWeight: "normal",
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
});
