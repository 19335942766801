import { Link, LinkProps } from "@chakra-ui/react";

export type EmailLinkProps = Omit<LinkProps, "href"> & {
  email: string | null | undefined;
};

export default function EmailLink({
  children,
  email,
  ...rest
}: EmailLinkProps) {
  return (
    <Link href={`mailto:${email}`} {...rest}>
      {children ?? email}
    </Link>
  );
}
