import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { menuAnatomy as parts } from "@chakra-ui/anatomy";

const msch = createMultiStyleConfigHelpers(parts.keys);

export default msch.defineMultiStyleConfig({
  // Styles for the base style
  baseStyle: {
    list: { zIndex: 4 },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
});
