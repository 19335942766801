/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  mutation AdminRefreshDataSourceTypeColumnValues($definitionId: UUID!) {\n    upsert: upsertColumnValuesForDataSourceTypeColumnValuesDefinition(\n      dataSourceTypeColumnValuesDefinitionId: $definitionId\n    ) {\n      dataSourceTypeColumnValuesDefinitionId\n      numSyncedValues\n      numNewValues\n      valuesLastUpdatedAt\n    }\n  }\n": types.AdminRefreshDataSourceTypeColumnValuesDocument,
    "\n  mutation AdminPitchableCampaignAdminSendToContact(\n    $campaignId: UUID!\n    $contactId: UUID!\n  ) {\n    sendPitchableCampaignToContact(\n      input: {\n        pitchableCampaignId: $campaignId\n        pitchableCampaignRetailerContactId: $contactId\n        force: true\n      }\n    ) {\n      pitchableCampaignEmailMessageId\n    }\n  }\n": types.AdminPitchableCampaignAdminSendToContactDocument,
    "\n  mutation AdminPitchableCampaignAdminSendToEmail(\n    $campaignId: UUID!\n    $email: String!\n    $salutation: String\n  ) {\n    sendPitchableCampaignToEmailAddress(\n      input: {\n        pitchableCampaignId: $campaignId\n        emailAddress: $email\n        salutation: $salutation\n      }\n    ) {\n      emailMessageId\n    }\n  }\n": types.AdminPitchableCampaignAdminSendToEmailDocument,
    "\n  mutation AdminPitchableCampaignAdminSendCampaign(\n    $campaignId: UUID!\n    $contactIds: [UUID!]\n  ) {\n    sendPitchableCampaignToValidDestinations(\n      input: {\n        pitchableCampaignId: $campaignId\n        pitchableCampaignRetailerContactIds: $contactIds\n      }\n    ) {\n      pitchableCampaignEmailMessageIds\n    }\n  }\n": types.AdminPitchableCampaignAdminSendCampaignDocument,
    "\n  mutation AdminPitchableEmailInboxForwardToBrand($id: UUID!) {\n    forwardReceivedPitchableEmailToBrand(\n      input: { receivedPitchableEmailMessageId: $id }\n    ) {\n      forwardedEmailMessageId\n    }\n  }\n": types.AdminPitchableEmailInboxForwardToBrandDocument,
    "\n  mutation AdminPitchableEmailInboxForwardToAddress(\n    $emailId: UUID!\n    $email: String!\n  ) {\n    forwardReceivedEmailToAddress(\n      input: {\n        receivedEmailMessageId: $emailId\n        emailAddress: $email\n        via: \"Pitchable\"\n      }\n    ) {\n      forwardedEmailMessageId\n    }\n  }\n": types.AdminPitchableEmailInboxForwardToAddressDocument,
    "\n  query AnalyticsProductsMappings($brandId: UUID!, $dataSourceProviderName: String!) {\n    analyticsSkuMappings(\n      brandId: $brandId,\n      dataSourceProviderName: $dataSourceProviderName\n    ) {\n      skuComponentTypes\n      products {\n        productName\n        upc\n        skuComponentId\n        extraIdentifiers {\n          label\n          value\n        }\n      }\n    }\n  }\n": types.AnalyticsProductsMappingsDocument,
    "\n  mutation InviteBrandUser($brandId: UUID!, $user: UserInput!) {\n    inviteBrandUser(brandId: $brandId, user: $user) {\n      brandUser {\n        id\n        brandId\n        createdAt\n        user {\n          id\n          email\n          firstName\n          lastName\n          fullName\n          image\n          inviteAcceptedAt\n        }\n      }\n    }\n  }\n": types.InviteBrandUserDocument,
    "\n  mutation ResendBrandUserInvite($brandUserId: UUID!) {\n    resendBrandUserInvite(brandUserId: $brandUserId) {\n      brandUser {\n        id\n        user {\n          id\n          email\n        }\n      }\n    }\n  }\n": types.ResendBrandUserInviteDocument,
    "\n  mutation ResetPasswordConfirm(\n    $id: String!\n    $token: String!\n    $newPassword: String!\n    $reNewPassword: String!\n  ) {\n    resetPasswordConfirm(\n      id: $id\n      token: $token\n      newPassword: $newPassword\n      reNewPassword: $reNewPassword\n    ) {\n      success\n      errors\n    }\n  }\n": types.ResetPasswordConfirmDocument,
    "\n  query RequestSampleInfo($pceid: UUID!, $pcrcid: UUID) {\n    pitchableRequestSampleInfo(pitchableCampaignEmailId: $pceid, pitchableCampaignRetailerContactId: $pcrcid) {\n      brandName\n      formData {\n        retailerName\n        name\n        email\n        phone\n        line1\n        line2\n        city\n        state\n        zip\n      }\n    }\n  }\n": types.RequestSampleInfoDocument,
    "\n  mutation RequestSample($input: PitchableRequestSampleMutationInput!) {\n    pitchableRequestSample(input: $input) {\n      success\n    }\n  }\n": types.RequestSampleDocument,
    "\n  fragment PitchableUnsubscribeActionButtonFields on PitchableUnsubscribe {\n    email\n    emailAddressId\n    brandId\n  }\n": types.PitchableUnsubscribeActionButtonFieldsFragmentDoc,
    "\n  mutation CreatePitchableUnsubscribe($input: CreatePitchableUnsubscribeMutationInput!) {\n    createPitchableUnsubscribe(input: $input) {\n      __typename\n    }\n  }\n": types.CreatePitchableUnsubscribeDocument,
    "\n  mutation DeletePitchableUnsubscribe($input: DeletePitchableUnsubscribeMutationInput!) {\n    deletePitchableUnsubscribe(input: $input) {\n      __typename\n    }\n  }\n": types.DeletePitchableUnsubscribeDocument,
    "\n  mutation SendResetEmail($email: String!) {\n    resetPassword(email: $email) {\n      success\n      errors\n    }\n  }\n": types.SendResetEmailDocument,
    "\n  query PitchableUnsubscribeVerify($eaid: UUID, $pcrcid: UUID) {\n    pitchableUnsubscribe(emailAddressId: $eaid, pitchableCampaignRetailerContactId: $pcrcid) {\n      brandId\n      brandName\n      email\n      emailAddressId\n      isUnsubscribed\n      isBrandUnsubscribed\n    }\n  }\n": types.PitchableUnsubscribeVerifyDocument,
    "\n  mutation GetToken($email: String!, $password: String!) {\n    auth: tokenAuth(email: $email, password: $password) {\n      token\n      refreshToken\n      refreshExpiresIn\n    }\n  }\n": types.GetTokenDocument,
    "\n  mutation RefreshToken($refreshToken: String!) {\n    refreshToken(refreshToken: $refreshToken) {\n      token\n      payload\n      refreshToken\n      refreshExpiresIn\n    }\n  }\n": types.RefreshTokenDocument,
    "\n  mutation RevokeToken($refreshToken: String!) {\n    revokeToken(refreshToken: $refreshToken) {\n      revoked\n    }\n  }\n": types.RevokeTokenDocument,
    "\n  mutation GetMetabaseToken($brandId: UUID!) {\n    getMetabaseToken(brandId: $brandId) {\n      token\n    }\n  }\n": types.GetMetabaseTokenDocument,
};

/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = serverGql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function serverGql(source: string): unknown;

/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation AdminRefreshDataSourceTypeColumnValues($definitionId: UUID!) {\n    upsert: upsertColumnValuesForDataSourceTypeColumnValuesDefinition(\n      dataSourceTypeColumnValuesDefinitionId: $definitionId\n    ) {\n      dataSourceTypeColumnValuesDefinitionId\n      numSyncedValues\n      numNewValues\n      valuesLastUpdatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation AdminRefreshDataSourceTypeColumnValues($definitionId: UUID!) {\n    upsert: upsertColumnValuesForDataSourceTypeColumnValuesDefinition(\n      dataSourceTypeColumnValuesDefinitionId: $definitionId\n    ) {\n      dataSourceTypeColumnValuesDefinitionId\n      numSyncedValues\n      numNewValues\n      valuesLastUpdatedAt\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation AdminPitchableCampaignAdminSendToContact(\n    $campaignId: UUID!\n    $contactId: UUID!\n  ) {\n    sendPitchableCampaignToContact(\n      input: {\n        pitchableCampaignId: $campaignId\n        pitchableCampaignRetailerContactId: $contactId\n        force: true\n      }\n    ) {\n      pitchableCampaignEmailMessageId\n    }\n  }\n"): (typeof documents)["\n  mutation AdminPitchableCampaignAdminSendToContact(\n    $campaignId: UUID!\n    $contactId: UUID!\n  ) {\n    sendPitchableCampaignToContact(\n      input: {\n        pitchableCampaignId: $campaignId\n        pitchableCampaignRetailerContactId: $contactId\n        force: true\n      }\n    ) {\n      pitchableCampaignEmailMessageId\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation AdminPitchableCampaignAdminSendToEmail(\n    $campaignId: UUID!\n    $email: String!\n    $salutation: String\n  ) {\n    sendPitchableCampaignToEmailAddress(\n      input: {\n        pitchableCampaignId: $campaignId\n        emailAddress: $email\n        salutation: $salutation\n      }\n    ) {\n      emailMessageId\n    }\n  }\n"): (typeof documents)["\n  mutation AdminPitchableCampaignAdminSendToEmail(\n    $campaignId: UUID!\n    $email: String!\n    $salutation: String\n  ) {\n    sendPitchableCampaignToEmailAddress(\n      input: {\n        pitchableCampaignId: $campaignId\n        emailAddress: $email\n        salutation: $salutation\n      }\n    ) {\n      emailMessageId\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation AdminPitchableCampaignAdminSendCampaign(\n    $campaignId: UUID!\n    $contactIds: [UUID!]\n  ) {\n    sendPitchableCampaignToValidDestinations(\n      input: {\n        pitchableCampaignId: $campaignId\n        pitchableCampaignRetailerContactIds: $contactIds\n      }\n    ) {\n      pitchableCampaignEmailMessageIds\n    }\n  }\n"): (typeof documents)["\n  mutation AdminPitchableCampaignAdminSendCampaign(\n    $campaignId: UUID!\n    $contactIds: [UUID!]\n  ) {\n    sendPitchableCampaignToValidDestinations(\n      input: {\n        pitchableCampaignId: $campaignId\n        pitchableCampaignRetailerContactIds: $contactIds\n      }\n    ) {\n      pitchableCampaignEmailMessageIds\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation AdminPitchableEmailInboxForwardToBrand($id: UUID!) {\n    forwardReceivedPitchableEmailToBrand(\n      input: { receivedPitchableEmailMessageId: $id }\n    ) {\n      forwardedEmailMessageId\n    }\n  }\n"): (typeof documents)["\n  mutation AdminPitchableEmailInboxForwardToBrand($id: UUID!) {\n    forwardReceivedPitchableEmailToBrand(\n      input: { receivedPitchableEmailMessageId: $id }\n    ) {\n      forwardedEmailMessageId\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation AdminPitchableEmailInboxForwardToAddress(\n    $emailId: UUID!\n    $email: String!\n  ) {\n    forwardReceivedEmailToAddress(\n      input: {\n        receivedEmailMessageId: $emailId\n        emailAddress: $email\n        via: \"Pitchable\"\n      }\n    ) {\n      forwardedEmailMessageId\n    }\n  }\n"): (typeof documents)["\n  mutation AdminPitchableEmailInboxForwardToAddress(\n    $emailId: UUID!\n    $email: String!\n  ) {\n    forwardReceivedEmailToAddress(\n      input: {\n        receivedEmailMessageId: $emailId\n        emailAddress: $email\n        via: \"Pitchable\"\n      }\n    ) {\n      forwardedEmailMessageId\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  query AnalyticsProductsMappings($brandId: UUID!, $dataSourceProviderName: String!) {\n    analyticsSkuMappings(\n      brandId: $brandId,\n      dataSourceProviderName: $dataSourceProviderName\n    ) {\n      skuComponentTypes\n      products {\n        productName\n        upc\n        skuComponentId\n        extraIdentifiers {\n          label\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AnalyticsProductsMappings($brandId: UUID!, $dataSourceProviderName: String!) {\n    analyticsSkuMappings(\n      brandId: $brandId,\n      dataSourceProviderName: $dataSourceProviderName\n    ) {\n      skuComponentTypes\n      products {\n        productName\n        upc\n        skuComponentId\n        extraIdentifiers {\n          label\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation InviteBrandUser($brandId: UUID!, $user: UserInput!) {\n    inviteBrandUser(brandId: $brandId, user: $user) {\n      brandUser {\n        id\n        brandId\n        createdAt\n        user {\n          id\n          email\n          firstName\n          lastName\n          fullName\n          image\n          inviteAcceptedAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InviteBrandUser($brandId: UUID!, $user: UserInput!) {\n    inviteBrandUser(brandId: $brandId, user: $user) {\n      brandUser {\n        id\n        brandId\n        createdAt\n        user {\n          id\n          email\n          firstName\n          lastName\n          fullName\n          image\n          inviteAcceptedAt\n        }\n      }\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation ResendBrandUserInvite($brandUserId: UUID!) {\n    resendBrandUserInvite(brandUserId: $brandUserId) {\n      brandUser {\n        id\n        user {\n          id\n          email\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ResendBrandUserInvite($brandUserId: UUID!) {\n    resendBrandUserInvite(brandUserId: $brandUserId) {\n      brandUser {\n        id\n        user {\n          id\n          email\n        }\n      }\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation ResetPasswordConfirm(\n    $id: String!\n    $token: String!\n    $newPassword: String!\n    $reNewPassword: String!\n  ) {\n    resetPasswordConfirm(\n      id: $id\n      token: $token\n      newPassword: $newPassword\n      reNewPassword: $reNewPassword\n    ) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPasswordConfirm(\n    $id: String!\n    $token: String!\n    $newPassword: String!\n    $reNewPassword: String!\n  ) {\n    resetPasswordConfirm(\n      id: $id\n      token: $token\n      newPassword: $newPassword\n      reNewPassword: $reNewPassword\n    ) {\n      success\n      errors\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  query RequestSampleInfo($pceid: UUID!, $pcrcid: UUID) {\n    pitchableRequestSampleInfo(pitchableCampaignEmailId: $pceid, pitchableCampaignRetailerContactId: $pcrcid) {\n      brandName\n      formData {\n        retailerName\n        name\n        email\n        phone\n        line1\n        line2\n        city\n        state\n        zip\n      }\n    }\n  }\n"): (typeof documents)["\n  query RequestSampleInfo($pceid: UUID!, $pcrcid: UUID) {\n    pitchableRequestSampleInfo(pitchableCampaignEmailId: $pceid, pitchableCampaignRetailerContactId: $pcrcid) {\n      brandName\n      formData {\n        retailerName\n        name\n        email\n        phone\n        line1\n        line2\n        city\n        state\n        zip\n      }\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation RequestSample($input: PitchableRequestSampleMutationInput!) {\n    pitchableRequestSample(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation RequestSample($input: PitchableRequestSampleMutationInput!) {\n    pitchableRequestSample(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  fragment PitchableUnsubscribeActionButtonFields on PitchableUnsubscribe {\n    email\n    emailAddressId\n    brandId\n  }\n"): (typeof documents)["\n  fragment PitchableUnsubscribeActionButtonFields on PitchableUnsubscribe {\n    email\n    emailAddressId\n    brandId\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation CreatePitchableUnsubscribe($input: CreatePitchableUnsubscribeMutationInput!) {\n    createPitchableUnsubscribe(input: $input) {\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePitchableUnsubscribe($input: CreatePitchableUnsubscribeMutationInput!) {\n    createPitchableUnsubscribe(input: $input) {\n      __typename\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation DeletePitchableUnsubscribe($input: DeletePitchableUnsubscribeMutationInput!) {\n    deletePitchableUnsubscribe(input: $input) {\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePitchableUnsubscribe($input: DeletePitchableUnsubscribeMutationInput!) {\n    deletePitchableUnsubscribe(input: $input) {\n      __typename\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation SendResetEmail($email: String!) {\n    resetPassword(email: $email) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation SendResetEmail($email: String!) {\n    resetPassword(email: $email) {\n      success\n      errors\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  query PitchableUnsubscribeVerify($eaid: UUID, $pcrcid: UUID) {\n    pitchableUnsubscribe(emailAddressId: $eaid, pitchableCampaignRetailerContactId: $pcrcid) {\n      brandId\n      brandName\n      email\n      emailAddressId\n      isUnsubscribed\n      isBrandUnsubscribed\n    }\n  }\n"): (typeof documents)["\n  query PitchableUnsubscribeVerify($eaid: UUID, $pcrcid: UUID) {\n    pitchableUnsubscribe(emailAddressId: $eaid, pitchableCampaignRetailerContactId: $pcrcid) {\n      brandId\n      brandName\n      email\n      emailAddressId\n      isUnsubscribed\n      isBrandUnsubscribed\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation GetToken($email: String!, $password: String!) {\n    auth: tokenAuth(email: $email, password: $password) {\n      token\n      refreshToken\n      refreshExpiresIn\n    }\n  }\n"): (typeof documents)["\n  mutation GetToken($email: String!, $password: String!) {\n    auth: tokenAuth(email: $email, password: $password) {\n      token\n      refreshToken\n      refreshExpiresIn\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation RefreshToken($refreshToken: String!) {\n    refreshToken(refreshToken: $refreshToken) {\n      token\n      payload\n      refreshToken\n      refreshExpiresIn\n    }\n  }\n"): (typeof documents)["\n  mutation RefreshToken($refreshToken: String!) {\n    refreshToken(refreshToken: $refreshToken) {\n      token\n      payload\n      refreshToken\n      refreshExpiresIn\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation RevokeToken($refreshToken: String!) {\n    revokeToken(refreshToken: $refreshToken) {\n      revoked\n    }\n  }\n"): (typeof documents)["\n  mutation RevokeToken($refreshToken: String!) {\n    revokeToken(refreshToken: $refreshToken) {\n      revoked\n    }\n  }\n"];
/**
 * The serverGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function serverGql(source: "\n  mutation GetMetabaseToken($brandId: UUID!) {\n    getMetabaseToken(brandId: $brandId) {\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation GetMetabaseToken($brandId: UUID!) {\n    getMetabaseToken(brandId: $brandId) {\n      token\n    }\n  }\n"];

export function serverGql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;